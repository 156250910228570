import React, { useEffect } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import CloudHostingIllustration from './CloudHosting';
import Config from './Config';

function App() {
  useEffect(() => {
    // Forward the login link
    var currentHref = window.location.href;
    if (currentHref.includes('continueUrl'))
    {
      // Check the continue url and redirect if necessary
      let urlParams = new URLSearchParams(currentHref);
      let continueUrl = urlParams.get('continueUrl');
      let currentOrigin = location.origin;
  
      if (continueUrl != currentOrigin)
      {
        let forwardUrl = currentHref.replace(currentOrigin, continueUrl);
        console.log('forward url', forwardUrl);
        location.replace(forwardUrl);
        return;
      }
    }
  }, []);

  return (
    <Box
      minHeight={'calc(100vh - 64px - 183px)'}
      display={'flex'}
      alignItems={'center'}
      height='100%'
    >
      <Grid padding='1em' container spacing={4}>
        <Grid item container justifyContent={'center'} xs={12}>
          <Box height={'100%'} width={'100%'} maxWidth={500}>
            <CloudHostingIllustration width={'100%'} height={'100%'} />
          </Box>
        </Grid>
        <Grid
          item
          container
          alignItems={'center'}
          justifyContent={'center'}
          xs={12}
        >
          <Box display='flex' flexDirection='column' alignItems='center'>
            <Typography
              variant="h4"
              component={'h4'}
              align={'center'}
              gutterBottom
              sx={{ fontWeight: 700 }}
            >
              S3G Security is in maintenance mode
            </Typography>
            <Typography component="p" color="textSecondary" align={'center'}>
              We are working to deliver the coolest features ever.
              <br />
              Stay tuned! {Config.ETA_MESSAGE}
            </Typography>
            <img src='android-chrome-512x512.png' style={{
              marginTop: '1em',
              height: '5em'
            }} />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}

export default App;
